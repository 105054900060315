import React, { useContext, useEffect, useState } from 'react'
import BasicCard from '../../ux/cards/BasicCard'
import BasicTipTapRealtime from '../../editors/BasicTipTapRealtime'
import meetingContext from '../../../context/meetingContext';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { doc, onSnapshot, query, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../../utils/firebase';

//////////////////////////////////////////
// This component must be wrapped in a MeetingContextWrapper
//////////////////////////////////////////
// TODO: Validate permssioning - READ and LIMIT FIELDS THAT CAN BE CHANGED & FIELD TYPE
// TODO: Have the server check if the user is still editing (window close)
//////////////////////////////////////////

export default function MeetingPrepWidget(props) {

    // Define the props
    let {widgetDetails} = props

    let title = get(widgetDetails, "title", "No title provided");
    let description = get(widgetDetails, "description", "No description provided");
    let widgetId = get(widgetDetails, "id", null);
    let documentContent = get(widgetDetails, "content", null);
    let editing = get(widgetDetails, "editing", null);
    const meetingData = useContext(meetingContext);
    const user = useSelector((state) => state.user);
    const uid = get(user, "credentials.uid", undefined);
    const meetingId = get(meetingData, "meetingId", undefined);
    const [activeState, setActiveState] = useState(null);

    const onFocus = () => {
        const docData = {
            editing: uid,
            editingTimestamp: serverTimestamp()
        };
        const docRef = doc(db, "meetings", meetingId, "private", widgetId);
        updateDoc(docRef, docData);
    }

    const onBlur = (content) => {
        blurAction(content)
    }

    const blurAction = (content) => {
        const docData = {
            editing: null,
            editingTimestamp: null
        };
        if (content) {
            docData.content = content;
        }
        const docRef = doc(db, "meetings", meetingId, "private", widgetId);
        updateDoc(docRef, docData);
    }

    // If editing is not null, set the active state to editing
    useEffect(() => {
        if (editing === uid) {
            setActiveState("self");
        } else if (editing !== null) {
            setActiveState("other")
        } else {
            setActiveState(null);
        }
    }, [editing])
    
  return (
    
    <BasicCard title={title} description={description} activeState={activeState}>
        <BasicTipTapRealtime activeState={activeState} onFocus={onFocus} onBlur={onBlur} id={widgetId} content={documentContent} />
    </BasicCard>
  )
}
