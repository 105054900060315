import NonDashLayout from "../../layouts/NonDashLayout";
import DashboardLayout from "../../layouts/DashboardLayout";
import Login from "../../pages/Login";
import Meetings from "../../pages/Meetings";
import { ProtectedRoute } from "./ProtectedRoute";
import { RequireNoAuth } from "./RequireNoAuth";
import appRoutes from "./routes";

import MeetingPrep from "../../pages/MeetingPrep";
import { AllowGuestRoute } from "./AllowGuestRoute";
import MeetingContextWrapper from "./MeetingContextWrapper";






const routesConfig = [
  {
    path: appRoutes.HOME,
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <div>Home</div>,
      },
      {
        path: appRoutes.MEETINGS,
        element: <Meetings />,
      },
      {
        path: appRoutes.EVENTS,
        element: <div>Events</div>,
      },
      {
        path: appRoutes.REPORTS,
        element: <div>Reports</div>,
      },
    ],
  },
  {
    path: appRoutes.HOME,
    element: (
      <RequireNoAuth>
        <NonDashLayout />
      </RequireNoAuth>
    ),
    children: [
      {
        path: appRoutes.LOGIN,
        element: <Login />,
      },
      {
        path: appRoutes.REGISTER,
        async lazy() {
          let Register = await import("../../pages/Register");
          return { Component: Register.default };
        },
      },
    ],
  },
  {
    path: appRoutes.MEET,
    element: (
      <AllowGuestRoute>
        <MeetingContextWrapper>
          <DashboardLayout />
        </MeetingContextWrapper>
      </AllowGuestRoute>
    ),
    children: [
      {
        index: true,
        element: <div>Meet - No Meeting Selected</div>,
      },
      {
        path: ':meetingId',
        element: <MeetingPrep />,
      },
    ],
  },
  {
    path: `${appRoutes.MEET}/:meetingId/room`,
    async lazy() {
      let MeetingRouteMasterLayout = await import("../../layouts/MeetingRouteMasterLayout")
      return { Component: MeetingRouteMasterLayout.default };
    },
  },
  

  {
    path: appRoutes.UNKNOWN,
    element: <div>Not Found</div>,
  },
];

export default routesConfig;
