import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/solid";

import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { get } from "lodash";
import FormTextField from "../../forms/FormTextField";
import { language } from "../../../../utils/strings/en-us";
import { UserIcon } from "@heroicons/react/24/outline";
import { callableFunction } from "../../../../utils/redux/actions/dataActions";
import StandaloneFormTextField from "../../forms/StandaloneFormTextField";

export default function AddPresenterModalContent(props) {

  const {setOpen} = props
  
  // All the data for this component come from the store in the ui modal meta


  let params = useParams();
  const ui = useSelector((state) => state.ui);
  const meetingId = get(ui, "modal.meta.meetingId", null);
  
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);

  



  //Submission Logic
  const onSubmit = async (data) => {
    setStep(2);
      const submitAction = await dispatch(callableFunction("inviteCoPresenter", {
          email: data.email,
          meetingId: ui.modal.meta.meetingId,
        }
    ));
    if (submitAction.status === 200) {
      dispatch({ type: "SHOW_NOTIFICATION", payload: {title: "Co-Presenter Added", message: "Co-presenter has been invited to this meeting.", type: "static", meta: {}} });
      dispatch({ type: "CLEAR_MODAL" })
    } else {
      setStep(1);
      console.log(submitAction.message);
      setError("email", {
        type: "custom",
        message: `${submitAction.message}`,
      });
    }
  };

  return (
    <>
      {step === 1 && (
        <form
          noValidate
          id="new-product-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex">
            <div className="mr-2 shrink-0 flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
              <UserIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
            </div>
            <div className="">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {language.newProductModalTitle}
                Add a Co-Presenter
              </Dialog.Title>
              <div className="">
                <p className="text-sm text-gray-500">
                  Co-presenters have editing permission.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <StandaloneFormTextField
              register={register}
              errors={errors}
              fieldName="email"
              noLabel
              placeholder="captain@flight.com"
              label="Co-Presenter Email"
              isRequired={true}
              maxLength={50}
              minLength={3}
              type="email"
            />
          </div>
          
          <div className="mt-5 sm:mt-6">
            <button
              id="newproduct-button-organization-name"
              type="submit"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
            >
              {language.newProductModalAction}
              Add
            </button>
          </div>
        </form>
      )}

      {step === 2 && "loading"}
      {step === 3 && "Success"}
    </>
  );
}
