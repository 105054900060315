import logo from "./logo.svg";
import "./App.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import routesConfig from "./utils/routes/routesConfig";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "./utils/redux/store";
import { getAuthenticatedUserData } from "./utils/redux/actions/userActions";
import FullScreenLoader from "./components/ux/loaders/FullScreenLoader";

// Authentication Check
const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch({ type: "SET_AUTH_STATE_KNOWN", payload: user.uid });
    store.dispatch(getAuthenticatedUserData());
  } else {
    store.dispatch({ type: "SET_AUTH_STATE_KNOWN" });
    store.dispatch({ type: "STOP_FULL_SCREEN_LOADING"})
  }
});

const router = createBrowserRouter(routesConfig);

function App() {
  return (
    <div className="App dark:bg-slate-900">
      <FullScreenLoader />
      <RouterProvider router={router} />
      
    </div>
  );
}

export default App;
