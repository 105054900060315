import React, { useState } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function DateTimePicker(props) {
  const {
    fieldName,
    register,
    errors,
    label,
    placeholder,
    isRequired,
    maxLength,
    minLength,
    type,
    autoComplete,
    noLabel,
    options,
    columns,
    watch,
    defaultValue,
    control,
  } = props;
 
  return (
    <div>
      {!noLabel && (
        <label
          htmlFor={fieldName}
          className="flex w-full justify-between text-sm font-medium text-gray-700"
        >
          <span>{label}</span>

          {errors[fieldName] && (
            <span className="text-red-600">{errors[fieldName].message}</span>
          )}
        </label>
      )}
      <div className="mt-1">
        <Controller
          defaultValue={new Date()}
          control={control}
          name={fieldName}
          rules={{
            required: { value: isRequired, message: `Please select at date` },
          }}
          render={({ field: { value, onChange } }) => (
<DatePicker
  selected={value}
  showTimeSelect
  onChange={onChange} //only when value has changed
  inline
  minDate={new Date()}
/>
          )}
        />
      </div>
    </div>
  );
}
