import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  or,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import { db } from "../../utils/firebase";
import { Link } from "react-router-dom";
import { get } from "lodash";
import dayjs from "dayjs";

export default function UserMeetingsList() {
  //Get the current user uid from redux
  const user = useSelector((state) => state.user);
  const uid = get(user, "credentials.uid");

  // Create an empty array to store the meetings
  const [meetings, setMeetings] = useState([]);

  // Set up realtime listener for meetings
  const dateFilter = new Date();

  useEffect(() => {
    if (uid) {
      // Define the query

      const timeFilter = new Date();
      timeFilter.setHours(0,0,0,0); //All meetings from midnight today onwards

      const q = query(
        collection(db, "meetings"),
        where("users", "array-contains", uid),
        where("meetingDateTime", ">=", timeFilter),
        orderBy("meetingDateTime", "asc"),
      );
      // Subscribe to the query
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const meetingsArray = [];
        querySnapshot.forEach((doc) => {
          // Add the document id to the document data
          meetingsArray.push(doc.data());
        });
        setMeetings(meetingsArray);
      });
      // Unsubscribe from the query when the component unmounts
      return () => {
        unsubscribe();
      };
    }
  }, [uid]);

  return (
    <div className="mt-6 border-t-2 pt-6">
    <h1>Upcoming Meetings - We think your local time is: {dayjs(dateFilter).format("hh:mm a | DD MM YYYY")}</h1>
      count: {meetings.length}
      <ul className="">
        {meetings.map((meeting, index) => {
          return (
            <Link key={index} to={`/meet/${meeting.meetingId}`}>
              <li className="hover:bg-gray-100 cursor-pointer border-t p-2 py-4 flex justify-between">
              <span>{dayjs.unix(get(meeting, "meetingDateTime.seconds", {})).format("D MMM")}</span>
              <span>{get(meeting, "meetingName", "")}</span>
              <span>{get(meeting, "meetingId", "")}</span>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}
