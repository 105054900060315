import { useCurrentEditor } from '@tiptap/react'
import React from 'react'

export default function TipTapMenuBar({editor, activeState}) {
  
    if (!editor) {
      return null
    }
  
    return (
      
      <div id="TipTapMenuBar" className={`bg-gray-50 flex shadow overflow-x-scroll transition-all ${activeState === "self" ? "h-10" : "h-0"}`}>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={`TipTapMenuButton ${editor.isActive('bold') ? 'is-active' : ''}`}
        >
          bold
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          className={`TipTapMenuButton ${editor.isActive('italic') ? 'is-active' : ''}`}
        >
          italic
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          className={`TipTapMenuButton ${editor.isActive('strike') ? 'is-active' : ''}`}
        >
          strike
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleCode()
              .run()
          }
          className={`TipTapMenuButton ${editor.isActive('code') ? 'is-active' : ''}`}
        >
          code
        </button>
        {/* <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          clear marks
        </button>
        <button onClick={() => editor.chain().focus().clearNodes().run()}>
          clear nodes
        </button>
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={`TipTapMenuButton ${editor.isActive('paragraph') ? 'is-active' : ''}`}
        >
          paragraph
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={`TipTapMenuButton ${editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}`}
        >
          h1
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={`TipTapMenuButton ${editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}`}
        >
          h2
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={`TipTapMenuButton ${editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}`}
        >
          h3
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          className={`TipTapMenuButton ${editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}`}
        >
          h4
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          className={`TipTapMenuButton ${editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}`}
        >
          h5
        </button>
        <button
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          className={`TipTapMenuButton ${editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}`}
        >
          h6
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`TipTapMenuButton ${editor.isActive('bulletList') ? 'is-active' : ''}`}
        >
          bullet list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`TipTapMenuButton ${editor.isActive('orderedList') ? 'is-active' : ''}`}
        >
          ordered list
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={`TipTapMenuButton ${editor.isActive('codeBlock') ? 'is-active' : ''}`}
        >
          code block
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={`TipTapMenuButton ${editor.isActive('blockquote') ? 'is-active' : ''}`}
        >
          blockquote
        </button>
        <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          horizontal rule
        </button>
        <button onClick={() => editor.chain().focus().setHardBreak().run()}>
          hard break
        </button> */}
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .undo()
              .run()
          }
        >
          undo
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .redo()
              .run()
          }
        >
          redo
        </button>
        <button
          onClick={() => editor.chain().focus().setColor('#958DF1').run()}
          className={`TipTapMenuButton ${editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}`}
        >
          purple
        </button>
      </div>
      
    )
  }