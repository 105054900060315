// React hook form to create a meeting

import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { callableFunction } from "../../../utils/redux/actions/dataActions";
import { useDispatch } from "react-redux";
import FormTextField from "../../ux/forms/FormTextField";
import DateTimePicker from "../../ux/forms/DateTimePicker";

export default function CreateMeetingForm() {
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    console.log(data);
    let submitForm = await dispatch(callableFunction("createMeeting", data));
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({});

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      className="flex flex-col gap-3"
    >
      <div className="flex flex-row gap-6">
        <div className="flex flex-col gap-6 grow">
          <FormTextField
            register={register}
            errors={errors}
            fieldName="meetingName"
            label="Meeting Name"
            placeholder=""
            isRequired={true}
            maxLength={100}
            minLength={1}
            type="text"
          />

          <FormTextField
            register={register}
            errors={errors}
            fieldName="meetingDescription"
            label="Meeting Description"
            placeholder=""
            isRequired={false}
            maxLength={500}
            minLength={1}
            type="text"
          />
        </div>

        <DateTimePicker
          register={register}
          errors={errors}
          fieldName="meetingDateTime"
          label="Meeting Date and Time"
          isRequired={true}
          control={control}
        />
      </div>

      <input
        type="submit"
        className="cursor-pointer w-full bg-blue-600 hover:bg-blue-700 p-3 rounded-md text-white"
      />
    </form>
  );
}
