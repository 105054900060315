import { useContext } from "react";
import meetingContext from "../../../context/meetingContext";
import { get } from "lodash";

const stats = [
  { name: "Accepted", value: "4" },
  { name: "Maybe", value: "2" },
  { name: "Declined", value: "1" },
  { name: "Awaiting Response", value: "3" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MeetingBasics(props) {
  const meetingData = useContext(meetingContext);

  const { classes } = props;
  return (
    <dl
      className={`w-full  mx-auto grid grid-cols-2 gap-px bg-gray-900/5 sm:grid-cols-4 lg:grid-cols-4 ${classes}`}
    >
      <div

        className="flex flex-wrap items-baseline justify-between bg-white gap-x-4 gap-y-2 px-4 py-10 sm:px-6 xl:px-8"
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">
          Accepted
        </dt>
        <dd
          className={classNames(
            
            "text-xs font-medium text-gray-300"
          )}
        >
          info
        </dd>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          {get(meetingData, "users", []).length}
        </dd>
      </div>
      <div

        className="flex flex-wrap items-baseline justify-between bg-white gap-x-4 gap-y-2 px-4 py-10 sm:px-6 xl:px-8"
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">
          Maybe
        </dt>
        <dd
          className={classNames(
            
            "text-xs font-medium text-gray-300"
          )}
        >
          info
        </dd>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          NA
        </dd>
      </div>
      <div

        className="flex flex-wrap items-baseline justify-between bg-white gap-x-4 gap-y-2 px-4 py-10 sm:px-6 xl:px-8"
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">
          Declined
        </dt>
        <dd
          className={classNames(
            
            "text-xs font-medium text-gray-300"
          )}
        >
          info
        </dd>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          NA
        </dd>
      </div>
      <div
        
        className="flex flex-wrap items-baseline justify-between bg-white gap-x-4 gap-y-2 px-4 py-10 sm:px-6 xl:px-8"
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">
          Awaiting Response
        </dt>
        <dd
          className={classNames(
            
            "text-xs font-medium text-gray-300"
          )}
        >
          info
        </dd>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          NA
        </dd>
      </div>
    </dl>
  );
}
