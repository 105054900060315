import React from "react";

export default function FormTextField(props) {
  const {
    fieldName,
    register,
    errors,
    label,
    placeholder,
    isRequired,
    maxLength,
    minLength,
    type,
    defaultValue,
    autoComplete,
    disabled,
    noLabel,
    normalize,
    onChange,
  } = props;

  // Consistent Patterning
  const enforcePattern = (type) => {
    let output = {};

    // Email Pattern
    if (type === "email") {
      output = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email address",
      };
    }

    return output;
  };

  return (
    <div>
      {!noLabel && (
        <label
          htmlFor={fieldName}
          className="flex w-full justify-between text-sm font-medium text-gray-700"
        >
          <span>{label}</span>

          {errors[fieldName] && (
            <span className="text-red-600">
              {errors[fieldName].message}
            </span>
          )}
        </label>
      )}
      <div className="mt-1">
        <input
          id={fieldName}
          readOnly={disabled}
          placeholder={placeholder}
          name={fieldName}
          defaultValue={defaultValue}
          type={type}
          autoComplete={autoComplete}
          required={isRequired}
          {...register(fieldName, {
            onChange: onChange,
            required: {
              value: isRequired,
              message: "Required",
            },
            maxLength: {
              value: maxLength,
              message: `Must be less than ${maxLength} characters`,
            },
            minLength: {
              value: minLength,
              message: `Must be more than ${minLength} characters`,
            },
            pattern: {
              ...enforcePattern(type),
            },
          })}
          className={`
            ${errors[fieldName] ? "formTextField-error" : "formTextField-input"}
            ${disabled && "opacity-50"}
            `}
        />
      </div>
    </div>
  );
}
