import React from "react";

export default function BasicCard(props) {
  const {
    title,
    description,
    buttonText,
    buttonAction,
    children,
    activeState,
  } = props;

  return (
    <div className={`flex flex-col border rounded-lg group relative overflow-hidden ${activeState === "self" ? "border-blue-500" : "border-gray-200" } `}>
      {activeState === "other" && (
        <div className="absolute left-0 top-0 bottom-0 right-0 bg-gray-100 bg-opacity-90 flex items-center justify-center z-40 ">
          Someone else is editing this content.
        </div>
      )}

      <div className={`border-b ${activeState === "self" ? "border-blue-200 bg-blue-50" : "border-gray-200" } px-4 py-5 sm:px-6` }>
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="">
            <h3 className={`text-base font-semibold leading-6  ${activeState === "self" ? "text-blue-600" : "text-gray-900" }`}>
              {title}
            </h3>
            <p className="mt-1 text-sm text-gray-500">{description}</p>
          </div>

          <div className=" flex-shrink-0">
            {buttonText && (
              <button
                type="button"
                onClick={buttonAction}
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {buttonText}
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="h-full relative">{children}</div>
    </div>
  );
}
