import { doc, collection, limit, onSnapshot, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { db } from "../../utils/firebase";
import { get } from "lodash";
import MeetingContext from "../../context/meetingContext";
import JSONPretty from "react-json-pretty";
var JSONPrettyMon = require('react-json-pretty/dist/monikai');



// This component will setup the listener for the meeting and pass the necessary data to the subcomponents

export default function MeetingContextWrapper({children}) {
  
  //Get the current user uid from redux
  const user = useSelector((state) => state.user);
  const uid = get(user, "credentials.uid", undefined);

  // Get the meetingId from the url
  const meetingId = useParams().meetingId;

  // Create states to store meeting data, user data, and the merged data
  const [meetingData, setMeetingData] = useState({});
  const [transcriptionData, setTranscriptionData] = useState({});
  const [userData, setUserData] = useState({});
  const [mergedData, setMergedData] = useState({});
  const [privateData, setPrivateData] = useState([]);

  // Set up realtime listener for the meeting
  useEffect(() => {
    if (meetingId) {
      const q = query(doc(db, "meetings", meetingId));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.exists()) {
          setMeetingData(querySnapshot.data());
        } else {
          console.log("No such document!");
        }
      });
      // Unsubscribe from the query when the component unmounts
      return () => {
        unsubscribe();
      };
    }
  }, [meetingId]);

  // Setup a realtime listener for the meeting transcription
  useEffect(() => {
    if (meetingId) {
      const q = query(doc(db, "meetings", meetingId, "meta", "transcription"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if (querySnapshot.exists()) {
          setTranscriptionData(querySnapshot.data());
        } else {
          console.log("No such document!");
        }
      });
      // Unsubscribe from the query when the component unmounts
      return () => {
        unsubscribe();
      };
    }
  }, [meetingId]);

  // Setup realtime listener for the user permissions
  useEffect(() => {
    // If we have a meetingId and a uid, setup the listener
    if (meetingId && uid) {
      const docRef = doc(db, "meetings", meetingId, "users", uid);
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          setUserData(doc.data(),);
        } else {
          setUserData({
              role: "unassigned",
          });
        }
      });
      // Unsubscribe from the query when the component unmounts
      return () => {
        unsubscribe();
      };
    }
  }, [meetingId, uid, user.authStateKnown]);

  // If the user is a meeting owner or presenter, setup a realtime listener for all documents in the private subcollection
  useEffect(() => {
    if (userData.role === "owner" || userData.role === "presenter") {
      // Define the query
      const q = query(
        collection(db, "meetings", meetingId, "private")
      );
      // Subscribe to the query
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const privateDataArray = [];
        querySnapshot.forEach((doc) => {
          // Add the document id to the document data
          privateDataArray.push(doc.data());
        });
        setPrivateData(privateDataArray);
      });
      // Unsubscribe from the query when the component unmounts
      return () => {
        unsubscribe();
      };
    }
  }, [userData]);

  // Combine the meeting and user data into a single object and update whenever there is a change
  useEffect(() => {
    setMergedData({ ...meetingData, user: userData, transcriptionData, privateData });
  }, [meetingData, userData, transcriptionData, privateData]);

  return (
    <MeetingContext.Provider value={mergedData}>
      {children}
      {/* <JSONPretty className="opacity-50 fixed bottom-0 right-0 w-96 overflow-scroll h-36" theme={JSONPrettyMon} id="json-pretty" data={mergedData}></JSONPretty> */}
    </MeetingContext.Provider>
  );
}
