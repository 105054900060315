import React from "react";
import LoginForm from "../components/authentication/LoginForm";


export default function Login() {
  return (
    <div className="flex min-h-screen">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full min-w-sm lg:w-96 ">
            <LoginForm />
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block bg-blue-950 m-10 rounded-3xl">
        <h1 className="absolute bottom-0 text-white text-lg m-16">Sign in to Flight</h1>
      </div>
    </div>
  );
}
