const appRoutes = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    MEETINGS: '/meetings',
    MEET: '/meet',
    TEAM: '/team',
    NOTEBOOK: '/notebook',
    PLAYBOOKS: '/playbooks',    
    EVENTS: '/events',
    REPORTS: '/reports',
    UNKNOWN: '*',
}

export default appRoutes;