import React from 'react'
import { useDispatch } from 'react-redux'
import { logOut } from '../utils/redux/actions/userActions'

export default function UpgradeAccount() {

    const dispatch = useDispatch()

    const handleLogOut = () => {
        dispatch(logOut())
    }

  return (
    <div>You must upgrade from a guest user to access all of Flight's features.
        <button onClick={handleLogOut}>Logout</button>
    </div>
  )
}
