import React from "react";
import { Outlet } from "react-router-dom";
import Modal from "../components/ux/modals/Modal";

export default function NonDashLayout() {
  return (
    <div className="NoDash">
      <Outlet />
      <Modal />
    </div>
  );
}
