import { Fragment, useContext } from 'react'

import { Menu, Transition } from '@headlessui/react'
import meetingContext from '../../../context/meetingContext'

import { get } from 'lodash'
import { Calendar, Video } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'
import { DocumentIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useDispatch } from 'react-redux'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MeetingPrepTemplateTitle(props) {

    
    const {classes} = props;
    const meetingData = useContext(meetingContext);
  const dispatch = useDispatch();


    const handleAddSection = () => {
      dispatch({ type: "SHOW_MODAL", payload: { type: "addTemplateSection", meta: {meetingId: meetingData.meetingId} } })
    }
  

  return (
    <div className={`lg:flex lg:items-center lg:justify-between ${classes} border-t mt-6 pt-6`}>
      <div className="min-w-0 flex-1">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
          Meeting Content
        </h2>
        

      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">
        {/* <span className="hidden sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <PencilIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Edit
          </button>
        </span> */}

        <span className="ml-3 hidden sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <DocumentIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Use Meeting Template
          </button>
        </span>

        <span className="sm:ml-3">
          <button
          onClick={handleAddSection}
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Add Section
          </button>
        </span>


      </div>
    </div>
  )
}
