import React from "react";
import {
  BookOpenIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  PresentationChartBarIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import appRoutes from "../../utils/routes/routes";
import DevBox from "../dev/DevBox";
import Logo from "../brand/Logo";

const navigation = [
  { name: "Home", link: appRoutes.HOME, icon: HomeIcon },
  { name: "Meetings", link: appRoutes.MEETINGS, altLink: appRoutes.MEET, icon: UsersIcon},
  { name: "Playbooks", link: appRoutes.PLAYBOOKS, icon: PresentationChartBarIcon},
  { name: "Notes and Actions", link: appRoutes.EVENTS, icon: DocumentDuplicateIcon},
  { name: "AI Notebook", link: appRoutes.NOTEBOOK, icon: BookOpenIcon},
  { name: "Team", link: appRoutes.TEAM, icon: UsersIcon},
  { name: "Insights", link: appRoutes.REPORTS, icon: ChartPieIcon},
];
const teams = [
  { id: 1, name: "Heroicons", link: "#", initial: "H", current: false },
  { id: 2, name: "Tailwind Labs", link: "#", initial: "T", current: false },
  { id: 3, name: "Workcation", link: "#", initial: "W", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



export default function Sidebar() {

// Get the current location.
const location = useLocation();
const pathname = location.pathname;
const firstPath = pathname.split('/')[1];
const addPathSlash = '/' + firstPath;

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-slate-900 px-6 pb-4">
      <div className="flex h-16 shrink-0 mt-2 items-center">
<Logo icon text />
      </div>
    
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.link}
                    className={classNames(
                      addPathSlash === item.link
                        ? "bg-gray-50 text-blue-600"
                        : "text-gray-700 hover:text-blue-600 hover:bg-gray-50 hover:border-blue-600",
                      "group flex gap-x-3 transition-all duration-200 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <div
                    className={`${addPathSlash === item.link || addPathSlash === item.altLink ? "opacity-100" : "opacity-0 group-hover:opacity-100"} w-1 bg-blue-600 transition-all duration-200 rounded-full`}
                    ></div>
                    <item.icon
                      className={classNames(
                        addPathSlash === item.link
                          ? "text-blue-600"
                          : "text-gray-400 group-hover:text-blue-600",
                        "h-6 w-6 shrink-0 transition-all duration-200"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <div className="text-xs font-semibold leading-6 text-gray-400">
              Your teams
            </div>
            <ul className="-mx-2 mt-2 space-y-1">
              {teams.map((team) => (
                <li key={team.name}>
                  <a
                    href={team.href}
                    className={classNames(
                      team.current
                        ? "bg-gray-50 text-blue-600"
                        : "text-gray-700 hover:text-blue-600 hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <span
                      className={classNames(
                        team.current
                          ? "text-blue-600 border-blue-600"
                          : "text-gray-400 border-gray-200 group-hover:border-blue-600 group-hover:text-blue-600",
                        "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                      )}
                    >
                      {team.initial}
                    </span>
                    <span className="truncate">{team.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li className="mt-auto">
            <DevBox />
            <Link
              to="settings"
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-blue-600"
            >
              <Cog6ToothIcon
                className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-blue-600"
                aria-hidden="true"
              />
              Settings
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
