import React from "react";
import CreateMeetingForm from "../components/meet/create/CreateMeetingForm";
import UserMeetingsList from "../components/meet/UserMeetingsList";

export default function Meetings() {
  return (
    <div>
      <CreateMeetingForm />
      <UserMeetingsList />
    </div>
  );
}
