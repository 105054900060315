import React, { useContext } from 'react'
import BasicCard from '../../ux/cards/BasicCard'
import meetingContext from '../../../context/meetingContext';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { UserIcon } from '@heroicons/react/24/outline';
const people = [
  {
    name: 'Leslie Alexander',
    email: 'leslie.alexander@example.com',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
  },
  {
    name: 'Michael Foster',
    email: 'michael.foster@example.com',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
  },
  {
    name: 'Dries Vincent',
    email: 'dries.vincent@example.com',
    imageUrl:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    href: '#',
  },
  // {
  //   name: 'Lindsay Walton',
  //   email: 'lindsay.walton@example.com',
  //   imageUrl:
  //     'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //   href: '#',
  // },
  // {
  //   name: 'Courtney Henry',
  //   email: 'courtney.henry@example.com',
  //   imageUrl:
  //     'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //   href: '#',
  // },
  // {
  //   name: 'Tom Cook',
  //   email: 'tom.cook@example.com',
  //   imageUrl:
  //     'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //   href: '#',
  // },
]

//////////////////////////////////////////
// This component must be wrapped in a MeetingContextWrapper
//////////////////////////////////////////

export default function CoPresentersWidget() {

  const dispatch = useDispatch();
  const meetingData = useContext(meetingContext);

  const coPresenters = get(meetingData, "users", [])

  const handleAddPresenter = () => {
    dispatch({ type: "SHOW_MODAL", payload: { type: "addPresenter", meta: {meetingId: meetingData.meetingId} } })
  }

  

  return (
    <BasicCard title="Presenters" description="Add or remove co-presenters to control acces." buttonText="Add" buttonAction={handleAddPresenter}>
          <div className=''>
      <ul role="list" className="divide-y divide-gray-100">
        {coPresenters.map((person) => (
          <li key={person} className="flex items-center justify-between gap-x-6 py-5 group/li hover:bg-slate-100 px-6 p-3 transition duration-200 cursor-pointer">
            <div className="flex min-w-0 gap-x-4">
              {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person} alt="" /> */}
              <UserIcon className="h-12 w-12 p-3 flex-none rounded-full bg-gray-100 text-gray-500" />
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">{person}</p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person}</p>
              </div>
            </div>
            <a
              href={person.href}
              className="rounded-md bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 opacity-0 group-hover/li:opacity-100 transition duration-200"
            >
              Remove
            </a>
          </li>
        ))}
      </ul>
    </div>
      </BasicCard>
  )
}
