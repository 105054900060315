import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { loginAction } from "../../utils/redux/actions/userActions";
import FormTextField from "../ux/forms/FormTextField";

export default function LoginForm() {
  //Use Functions
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);

  //Submission Logic
  const onSubmit = async (data) => {
    const loginRequest = await dispatch(loginAction(data));

    if (loginRequest.success) {
    } else {
      if (loginRequest.error.code === "auth/user-not-found") {
        setError("email", {
          type: "custom",
          message: `There is no account with this email`,
        });
      } else if (loginRequest.error.code === "auth/wrong-password") {
        setError("password", {
          type: "custom",
          message: `Wrong password. Try again or click 'Forgot Password' to reset it.`,
        });
      } else if (loginRequest.error.code === "auth/invalid-login-credentials") {
        setError("email", {
          type: "custom",
          message: `Invalid login credentials. Please try again.`,
        });
      } else {
        setError("password", {
          type: "custom",
          message: `There was an error logging you in. If this persists please contact support.`,
        });
      }
    }
  };

  return (
    <div className="w-full space-y-8">
      <form
        noValidate
        className="space-y-6"
        id="login-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="remember" value="true" />

        <FormTextField
          register={register}
          errors={errors}
          fieldName="email"
          label="Email Address"
          placeholder=""
          isRequired={true}
          maxLength={25}
          minLength={5}
          type="email"
          autoComplete="email"
        />

        <FormTextField
          register={register}
          errors={errors}
          fieldName="password"
          label="Password"
          placeholder=""
          isRequired={true}
          maxLength={25}
          minLength={6}
          type="password"
          autoComplete="current-password"
        />

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900"
            >
              Remember me
            </label>
          </div>

          <div className="text-sm">
            <Link
              to="passwordreset"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Forgot your password?
            </Link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  );
}
