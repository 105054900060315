import { get } from "lodash";
import { useSelector } from "react-redux";

import VersionAccessComingSoon from "../../pages/VersionAccessComingSoon";
import AllowGuestLogin from "../../pages/AllowGuestLogin";

// THIS ROUTE WILL REDIRECT UNAUTHENTICATED USERS TO THE LOGIN PAGE

export const AllowGuestRoute = ({ children }) => {

  const user = useSelector((state) => state.user);
  let authenticated = get(user, "authenticated");
  let displayName = get(user, "credentials.displayName", undefined);
  let build = get(user, "credentials.build", []);

  let isAllowed = true;
  let thisVersion = "beta";

  if (build.includes(thisVersion)) {
    isAllowed = true;
  }

//TODO: Add Force Display Name?

  if (authenticated) {
    return isAllowed
      ? children
      : <VersionAccessComingSoon />;
  } else {
    return <AllowGuestLogin />;
  }
};
