
import { getFunctions, httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";


// GENERIC FUNCTION FOR QUICK CALLS TO FIREBASE FUNCTIONS
// This function is used to call any firebase function. We hope that this will result in better structure and less code.
// functionName: String
// data: Object
export const callableFunction = (functionName, data) => async (dispatch) => {
    const request = httpsCallable(functions, functionName);
    const makeRequest = await request(data);
    return makeRequest.data;
  };
