import { get } from "lodash";
import { useSelector } from "react-redux";
import Login from "../../pages/Login";
import VersionAccessComingSoon from "../../pages/VersionAccessComingSoon";
import UpgradeAccount from "../../pages/UpgradeAccount";

// THIS ROUTE WILL REDIRECT UNAUTHENTICATED USERS TO THE LOGIN PAGE

export const ProtectedRoute = ({ children }) => {

  const user = useSelector((state) => state.user);
  let authenticated = get(user, "authenticated");
  let anonymous = get(user, "credentials.anonymous", true);
  let build = get(user, "credentials.build", []);

  let isAllowed = true;
  let thisVersion = "beta";

  if (build.includes(thisVersion)) {
    isAllowed = true;
  }

  if (authenticated) {
    return isAllowed
      ? anonymous
        ? <UpgradeAccount />
        : children
      : <VersionAccessComingSoon />;
  } else {
    return <Login />;
  }
};
