import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

export default function FullScreenLoader() {
  const ui = useSelector((state) => state.ui);
  let loader = get(ui, "fullScreenLoading");

  return (
    loader && (
      <div className="fixed left-0 top-0 w-screen bg-gray-100 text-center align-middle grid h-screen place-items-center z-[100] ">
        <div>
        <div className="squareContainer">
          <div className="square one green"></div>
          <div className="square two green"></div>
          <div className="square three green"></div>
          <div className="square two mid"></div>
          <div className="square three mid"></div>
          <div className="square four mid"></div>
          <div className="square three purple"></div>
          <div className="square four purple"></div>
          <div className="square five purple"></div>
        </div>
        <h1 className="mt-16 text-sm text-gray-600">Loading</h1>
        </div>
      </div>
    )
  );
}
