exports.language = {
    authentication: {
        inviteCodeLabel: "",
        inviteCodePlaceholder: "Invide Code",
        emailLabel: "Email Address",
        emailPlaceholder: "",
        passwordLabel: "Choose Password",
        passwordPlaceholder: "",
        confirmPasswordLabel: "Confirm Password",
        confirmPasswordPlaceholder: "",
        firstNameLabel: "First Name",
        firstNamePlaceholder: "",
        displayNameLabel: "Display Name",
        lastNameLabel: "Last Name",
        lastNamePlaceholder: "",
    },
}