import React from "react";
import LoginForm from "../components/authentication/LoginForm";
import RegisterGuest from "../components/authentication/RegisterGuest";

export default function AllowGuestLogin() {
  return (
    <div className="w-full flex min-h-screen">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 w-1/2">
        <div className="mx-auto w-full min-w-sm lg:w-96 ">
          <RegisterGuest />
        </div>
      </div>
      <div className="bg-blue-100 flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 w-1/2">
        <div className="mx-auto w-full min-w-sm lg:w-96 ">
          Or Login to Flight
          <LoginForm />
        </div>
      </div>
    </div>
  );
}
