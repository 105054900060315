import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  signInAnonymously,
} from "firebase/auth";
import firebase from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();

// AUTHENTICATION FUNCTIONS
const auth = getAuth(firebase);

// Register new user TODO: Add success confirmation in the component //TODO: add a new version of this for meetings
export const registerAction = (data) => async (dispatch) => {
  const register = httpsCallable(functions, "registerPlaybookUser");
  const registerRequest = await register(data);
  return registerRequest.data;
};

// Login user
export const loginAction = (data) => async (dispatch) => {
  try {
    const loginRequest = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};


// Login Anonymous user
export const loginAnonymous = (data) => async (dispatch) => {
  try {
    const loginRequest = await signInAnonymously(auth);
    // const registerAnon = httpsCallable(functions, "createAnonymousUser");
    // const registerRequest = await registerAnon(data);
    
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error: error,
    };
  }
};


export const getAuthenticatedUserData = () => async (dispatch) => {
  // Callable Functions
  const user = httpsCallable(functions, "getUserData");

  try {
    const getUser = await user();
    if (getUser.data.success) {
      dispatch({ type: "SET_AUTHENTICATED", payload: getUser.data });
    } else {
      signOut(auth);
      dispatch({ type: "SET_UNAUTHENTICATED" });
    }
  } catch (err) {
    console.log(err);
  }

  dispatch({ type: "STOP_FULL_SCREEN_LOADING" });
};

export const logOut = () => (dispatch) => {
  signOut(auth)
    .then(() => {
      dispatch({ type: "SET_UNAUTHENTICATED" });
      return { success: true };
    })
    .catch((error) => {
      // An error happened.
    });
};