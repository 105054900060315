import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  authStateKnown: false,
  authenticated: false,
  credentials: {},
};

const userReducer = createReducer(initialState, (builder) => {
  builder.addCase("SET_AUTH_STATE_KNOWN", (state, action) => {
    state.authStateKnown = true;
    state.credentials.uid = action.payload;
  });
  builder.addCase("SET_AUTHENTICATED", (state, action) => {
    state.authenticated = true;
    state.authStateKnown = true;
    state.credentials = {
      ...state.credentials,
      ...action.payload.credentials
    }
  });
  builder.addCase("SET_UNAUTHENTICATED", (state, action) => {
    state.authenticated = false;
    state.authStateKnown = true;
  });
});

export default userReducer;
