import "./TipTapStyle.css";

import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import {
  EditorContent,
  EditorProvider,
  useCurrentEditor,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TipTapMenuBar from "./TipTapMenuBar";
import { useSelector } from "react-redux";
import { get, set } from "lodash";

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
];

const editorProps = {
  attributes: {
    class:
      "prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none",
  },
};

export default function BasicTipTapRealtime(props) {
  let { onFocus, onBlur, content, activeState } = props;

  const [editorState, setEditorState] = useState("ready");

  const [disableEvents, setDisableEvents] = useState(false);

  const user = useSelector((state) => state.user);
  const uid = get(user, "credentials.uid", undefined);

  // If content is an empty array set to an empty string
  if (Array.isArray(content) && content.length === 0) {
    content = "";
  }

  const editor = useEditor({
    extensions,
    content,
  });

  const handleOnBlur = (event) => {

    // If the blur event is coming from a TipTapMenuButton, don't trigger the onBlur
    const eventTargetClasses = get(event, "relatedTarget.className", "");
    if (eventTargetClasses.includes("TipTapMenuButton")) {
      setDisableEvents(true);
      return;
    }

    onBlur(editor.getJSON());
  };

  const handleOnFocus = () => {
    // Don't trigger the onFocus if the blur event was triggered by a TipTapMenuButton
    if (disableEvents) {
      setDisableEvents(false);
      return;
    }
    onFocus();
  };

  //Push content update to the editor if it changes externally
  useEffect(() => {
    if (editor) {
      editor.commands.setContent(content);
    }
  }, [content]);

  function handleKeyDown(e) {
    if (e.key == "Tab") {
      e.preventDefault();
    }
  }

  return (
    <div className="flex flex-col h-full">
      <TipTapMenuBar editor={editor} activeState={activeState} />
      <EditorContent
        
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        editor={editor}
        className="w-full grow outline-none focus:outline-none"
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
