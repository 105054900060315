import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import FormTextField from "../ux/forms/FormTextField";
import { loginAnonymous } from "../../utils/redux/actions/userActions";
import { language } from "../../utils/strings/en-us";
import { useSearchParams } from "react-router-dom";
import { get } from "lodash";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { callableFunction } from "../../utils/redux/actions/dataActions";

export default function RegisterGuest() {
  //Use Functions
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [searchParams] = useSearchParams();

  const strings = get(language, "authentication", {});

  const user = useSelector((state) => state.user);
  let displayName = get(user, "credentials.displayName", undefined);
  let authenticated = get(user, "authenticated");

  // Create Anon User
  const createAnonUser = async () => {
    const loginRequest = await dispatch(loginAnonymous());
  };

  //Submission Logic
  const onSubmit = async (data) => {
    const addDisplayName = await dispatch(callableFunction("addDisplayName", data));
  };

  // Determine if we have a display name set
  useEffect(() => {
    if (authenticated && displayName === undefined) {
      setStep(1);
    }
  }, [displayName, authenticated]);

  // Component
  return (
    <div className="flex min-h-full flex-col justify-center">
      {step === 0 && (
        <div className="sm:mx-auto flex-col space-y-6 sm:w-full sm:max-w-md bg-white py-4 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center">
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
              Continue as a Guest
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              You can upgrade to a full Flight user at any time.
            </p>
            <button
              type="button"
              onClick={createAnonUser}
              className="mt-2 flex rounded-full bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-200"
            >
              Continue as Guest
              <ArrowRightCircleIcon className="h-5 w-5 ml-2" />
            </button>
          </div>
        </div>
      )}

      {(step === 1 || step === 2) && (
        <form
          noValidate
          id="registration-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="sm:mx-auto flex-col space-y-6 sm:w-full sm:max-w-md bg-white py-4 ">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900">
                Continue as a Guest
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                You can upgrade to a full Flight user at any time.
              </p>
            </div>
          </div>
          <div className="sm:mx-auto sm:w-full sm:max-w-md flex space-y w-full flex-col items-center">
            <FormTextField
              register={register}
              errors={errors}
              fieldName="displayName"
              label={strings.displayNameLabel}
              isRequired={true}
              maxLength={50}
              minLength={2}
              type="text"
              autoComplete="fname"
            />
            <div>


            <button
              disabled={step === 2}
              type="submit"
              className="mt-2 flex rounded-full bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-200"
            >
                    {step === 1 ? "Let's go" : "Loading..."}
              <ArrowRightCircleIcon className="h-5 w-5 ml-2" />
            </button>

             
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
