import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import CompanyOverview from "../components/meet/prep/CompanyOverview";
import MeetingBasics from "../components/meet/prep/MeetingBasics";
import MeetingPrepTitle from "../components/meet/prep/MeetingPrepTitle";
import meetingContext from "../context/meetingContext";
import { get } from "lodash";
import MeetingPrepWidget from "../components/meet/prep/MeetingPrepWidget";
import CoPresentersWidget from "../components/meet/prep/CoPresentersWidget";
import MeetingPrepTemplateTitle from "../components/meet/prep/MeetingPrepTemplateTitle";

export default function MeetingPrep() {
  const params = useParams();
  const meetingId = params.meetingId;

  const meetingData = useContext(meetingContext);
  const privateData = get(meetingData, "privateData", []);

  // filter the privateData array of objects to only return the objects that have a type of 'widget'
  const widgets = privateData.filter((item) => {
    return item.type === "widget";
  });

  const templateWidget = privateData.filter((item) => {
    return item.type === "templateWidget";
  });

  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        <MeetingPrepTitle classes="col-span-1 sm:col-span-2" />

        <MeetingBasics classes="col-span-1 sm:col-span-2" />

        {widgets.map((widgetDetails, index) => {
          return (
            <MeetingPrepWidget widgetDetails={widgetDetails} key={index} />
          );
        })}
        <CoPresentersWidget />
        <MeetingPrepTemplateTitle classes="col-span-1 sm:col-span-2" />
        {templateWidget.map((widgetDetails, index) => {
          return (
            <MeetingPrepWidget widgetDetails={widgetDetails} key={index} />
          );
        })}
      </div>



      <div></div>
    </div>
  );
}
