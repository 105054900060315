import React from "react";
import { useDispatch } from "react-redux";
import { callableFunction } from "../../utils/redux/actions/dataActions";


export default function DevBox() {

    const dispatch = useDispatch();

    // Trigger Action
const triggerAction = async() => {
  const makeCall = await dispatch(callableFunction("generateZoomSignature", {}));
    console.log(makeCall);
};

  return (
    <div className="bg-blue-200 w-full rounded-md border-2 border-blue-600 p-2 text-center">
      <div className="cursor-pointer" onClick={triggerAction}>Trigger Action</div>
    </div>
  );
}
