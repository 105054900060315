import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AddPresenterModalContent from "./modalContent/AddPresenterModalContent";
import AddTemplateSectionModalContent from "./modalContent/AddTemplateSectionModalContent";

export default function Modal() {
  const ui = useSelector((state) => state.ui);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  //Trigger notification when the UI state changes and timeout after 5 seconds
  useEffect(() => {
    if (ui.modal.show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [ui.modal]);

  // Case switcher for the different modal content
  const modalContent = () => {
    switch (ui.modal.type) {
      case "addPresenter":
        return <AddPresenterModalContent setOpen={setOpen} />;
        case "addTemplateSection":
          return <AddTemplateSectionModalContent setOpen={setOpen} />;
      default:
        return <div></div>;
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                {modalContent()}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
