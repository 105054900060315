import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  fullScreenLoading: true,
  notification: {
    show: false,
    type: "",
    title: "",
    message: "",
    meta: {},
  },
  modal: {
    show: false,
    type: "",
    meta: {},
  },
};

const uiReducer = createReducer(initialState, (builder) => {
  builder.addCase("STOP_FULL_SCREEN_LOADING", (state, action) => {
    state.fullScreenLoading = false;
  });

  builder.addCase("SHOW_MODAL", (state, action) => {
    state.modal = {
      show: true,
      type: action.payload.type,
      meta: { ...action.payload.meta },
    };
  });

  builder.addCase("CLEAR_MODAL", (state, action) => {
    state.modal = {
      show: false,
    };
  });

  builder.addCase("SHOW_NOTIFICATION", (state, action) => {
    state.notification = {
          show: true,
          type: action.payload.type,
          title: action.payload.title,
          message: action.payload.message,
          meta: { ...action.payload.meta },
        };
});

})

export default uiReducer;
