import { get } from "lodash";
import { useSelector } from "react-redux";
import Login from "../../pages/Login";
import VersionAccessComingSoon from "../../pages/VersionAccessComingSoon";
import { Navigate } from "react-router-dom";

// THIS ROUTE WILL REDIRECT AUTHENTICATED USERS TO THE HOME PAGE

export const RequireNoAuth = ({ children }) => {

  const user = useSelector((state) => state.user);
  let authenticated = get(user, "authenticated");


  if (!authenticated) {
    return children
  } else {
    return <Navigate to="/" />;
  }
};
