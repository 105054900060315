import { createReducer } from "@reduxjs/toolkit"

const initialState = {
    products: {},
    organizations: {},
    playbooks: {},
    assets: {},
    sharedPlaybooks: {},
    notebook: {},
  };

const dataReducer = createReducer(initialState, (builder) => {
  
  })

  export default dataReducer